<template lang="pug">
.button-wrapper(v-if="blok && showAuthorised || showUnauthorised || showAuthedNoPremium" v-editable="blok")
	NuxtLink(:to="linkDoesNotNeedLocale ? blok.cta.cached_url : localePath(blok.cta.cached_url)" :target="blok.target")
		SingaButton.is-regular.is-primary.sign-up-button {{ blok.title }}
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'

const userStore = useUserStore()
const { user } = storeToRefs(userStore)

const localePath = useLocalePath()

const props = defineProps({
	blok: {
		required: true,
		type: Object
	}
})

const linkDoesNotNeedLocale = computed(() => props.blok.cta.cached_url.includes('http') || props.blok.cta.linktype === 'story')

const { isUserAuthorised } = useUser()

const hasPremium = () => {
	if (user.value && user.value.subscription) {
		return user.value.subscription.is_active
	} else {
		return false
	}
}

const showAuthorised = computed(() => isUserAuthorised.value && props.blok.options === 'authed' && hasPremium())
const showUnauthorised = computed(() => !isUserAuthorised.value && props.blok.options === 'unauthed')
const showAuthedNoPremium = computed(() => user.value && isUserAuthorised.value && props.blok.options === 'authedNoPremium' && !hasPremium())
</script>

<style scoped lang="sass">
.sign-up-button
	margin-top: $spacing-32
	background-color: $color-green-60
	border-radius: $radius-default
	@include fontSize(s)
</style>
